import { StateSlice, Store } from "store/types";

export type AppSlice = {
  address: any;
  registerAddress: any;
  apikey: string;
  hash: string;
  dappDetails: any;
  allowance: any;
  iswalletConnect: any;
  walletNetwork: any;
  balance: any;
  activeTab: any;
  whitelisted: any[];
  feePayerAddresses: any[];
  feePayerBalance: any;
  popUpIsOpen: any;
  publicKey: any;
  signature: any;
  allowAllFunctions: any;
  allowAllScripts: any;
  particularDappData: any;
  particularFeepayeraddress: any;
  sponseredUsers: any;
  totalUsers: any;
  dappUsersPercentage: any;
  particularFeepayerAddressTxns: any;
  checkIsAdmin: any;
  changeHeader: any;
  details: any[];
  getFeePayerBalance: any;
  howMuchCanWithdraw: any;
  checkIsDapp: any
  updateAllowAllFunctions(payload: any): void;
  updateAllowAllScripts(payload: any): void;
  updateSignature(payload: any): void;
  updateWhitelisted(payload: any[]): void;
  updatePublicKey(payload: any): void;
  updateAddress(payload: any): void;
  updateRegisterAddress(payload: any): void;
  updateApiKey(payload: string): void;
  setHash(payload: any): void;
  updateDappDetails(payload: any): void;
  setAllowance(payload: any): void;
  updateWalletConnect(payload: any): void;
  setWalletNetwork(payload: any): void;
  updateBalance(payload: any): void;
  updateActiveTab(payload: any): void;
  updateFeePayerAddresses(payload: any): void;
  updateFeePayerBalance(payload: any): void;
  updatePopUpIsOpen(payload: any): void;
  updateParticularDappData(payload: any): void;
  updateParticularFeepayeraddress(payload: any): void;
  updateSponseredUsers(payload: any): void;
  setTotalUsers(payload: any): void;
  setDappUsersPercentage(payload: any): void;
  setParticularFeepayerAddressTxns(payload: any): void;
  setCheckIsAdmin(payload: any): void;
  setChangeHeader(payload: any): void;
  setDetails(payload: any): void;
  setGetFeePayerBalance(payload: any): void;
  setHowMuchCanWithdraw(payload: any): void;
  setCheckIsDapp(payload: any): void;
};

export const createAppSlice: StateSlice<Store, AppSlice> = (set) => ({
  address: "",
  updateAddress(payload: AppSlice["address"]) {
    set({ address: payload });
  },
  allowAllFunctions: "",
  updateAllowAllFunctions(payload: AppSlice["allowAllFunctions"]) {
    set({ allowAllFunctions: payload });
  },
  allowAllScripts: "",
  updateAllowAllScripts(payload: AppSlice["allowAllScripts"]) {
    set({ allowAllScripts: payload });
  },
  signature: "",
  updateSignature(payload: AppSlice["signature"]) {
    set({ signature: payload });
  },
  publicKey: "",
  updatePublicKey(payload: AppSlice["publicKey"]) {
    set({ publicKey: payload });
  },
  registerAddress: "",
  updateRegisterAddress(payload: AppSlice["registerAddress"]) {
    set({ registerAddress: payload });
  },
  apikey: "",
  updateApiKey(payload: AppSlice["apikey"]) {
    set({ apikey: payload });
  },
  hash: "",
  setHash(payload: AppSlice["hash"]) {
    set({ hash: payload });
  },
  dappDetails: "",
  updateDappDetails(payload: AppSlice["dappDetails"]) {
    set({ dappDetails: payload });
  },
  popUpIsOpen: "",
  updatePopUpIsOpen(payload: AppSlice["popUpIsOpen"]) {
    set({ popUpIsOpen: payload });
  },
  whitelisted: [],
  updateWhitelisted(payload: AppSlice["whitelisted"]) {
    set({ whitelisted: payload });
  },
  allowance: "",
  setAllowance(payload: AppSlice["allowance"]) {
    set({ allowance: payload });
  },
  iswalletConnect: false,
  updateWalletConnect(payload: AppSlice["iswalletConnect"]) {
    set({ iswalletConnect: payload });
  },
  walletNetwork: "",
  setWalletNetwork(payload: AppSlice["walletNetwork"]) {
    set({ walletNetwork: payload });
  },
  balance: "",
  updateBalance(payload: AppSlice["balance"]) {
    set({ balance: payload });
  },
  feePayerBalance: [],
  updateFeePayerBalance(payload: AppSlice["feePayerBalance"]) {
    set({ feePayerBalance: payload });
  },
  feePayerAddresses: [],
  updateFeePayerAddresses(payload: AppSlice["feePayerAddresses"]) {
    set({ feePayerAddresses: payload });
  },
  activeTab: localStorage.getItem("activeTab"),
  updateActiveTab(payload: AppSlice["activeTab"]) {
    set({ activeTab: payload });
    localStorage.setItem("activeTab", payload.toString());
  },
  particularDappData: "",
  updateParticularDappData(payload: AppSlice["particularDappData"]) {
    set({ particularDappData: payload });
  },
  particularFeepayeraddress: "",
  updateParticularFeepayeraddress(
    payload: AppSlice["particularFeepayeraddress"]
  ) {
    set({ particularFeepayeraddress: payload });
  },
  sponseredUsers: "",
  updateSponseredUsers(payload: AppSlice["sponseredUsers"]) {
    set({ sponseredUsers: payload });
  },
  totalUsers: "",
  setTotalUsers(payload: AppSlice["totalUsers"]) {
    set({ totalUsers: payload });
  },
  dappUsersPercentage: "",
  setDappUsersPercentage(payload: AppSlice["dappUsersPercentage"]) {
    set({ dappUsersPercentage: payload });
  },
  particularFeepayerAddressTxns: "",
  setParticularFeepayerAddressTxns(
    payload: AppSlice["particularFeepayerAddressTxns"]
  ) {
    set({ particularFeepayerAddressTxns: payload });
  },
  checkIsAdmin: "",
  setCheckIsAdmin(payload: AppSlice["checkIsAdmin"]) {
    set({ checkIsAdmin: payload });
  },
  changeHeader: "",
  setChangeHeader(payload: AppSlice["changeHeader"]) {
    set({ changeHeader: payload });
  },
  details: [],
  setDetails(payload: AppSlice["details"]) {
    set({ details: payload });
  },
  getFeePayerBalance: "",
  setGetFeePayerBalance(payload: AppSlice["getFeePayerBalance"]) {
    set({ getFeePayerBalance: payload });
  },
  howMuchCanWithdraw: "",
  setHowMuchCanWithdraw(payload: AppSlice["howMuchCanWithdraw"]) {
    set({ howMuchCanWithdraw: payload });
  },
  checkIsDapp: "",
  setCheckIsDapp(payload: AppSlice["checkIsDapp"]) {
    set({ checkIsDapp: payload });
  },
});
