import React, { useCallback, useEffect, useMemo } from "react";
import close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "store";
import { useAptosContext } from "./Walletprovider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClickAwayListener from "react-click-away-listener";
import { uint8ArrayToHex } from "utils/helpers";
import { fetchBalanceFromUser } from "utils/userBalanceFetch";

const WALLET_INSTALL_LINKS: { [key: string]: string } = {
  Petra:
    "https://chrome.google.com/webstore/detail/petra-aptos-wallet/ejjladinnckdgjemekebdpeokbikhfci",
  Fewcha:
    "https://chrome.google.com/webstore/detail/fewcha-move-wallet/ebfidpplhabeedpnhjnobghokpiioolj",
  Pontem: "https://pontem.network/",
  Rise: "https://chrome.google.com/webstore/detail/rise-aptos-wallet/hbbgbephgojikajhfbomhlmmollphcad",
  Martian:
    "https://chrome.google.com/webstore/detail/martian-wallet/efbglgofoippbgcjepnhiblaibcnclgk",
  Nightly: "https://nightly.app/",
};

const ConnectWallet: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    updateSignature,
    setWalletNetwork,
    updateWalletConnect,
    updatePublicKey,
    updateAddress,
    updateBalance,
    address,
  } = useStore();
  const {
    wallets: aptosWallets,
    connect,
    disconnect,
    connected,
    network,
    signMessage,
    account,
  } = useAptosContext();

  const [detected, undetected] = useMemo(() => {
    const detected: any[] = [];
    const undetected: any[] = [];

    for (const wallet of aptosWallets as any) {
      if (
        wallet.readyState === "Installed" ||
        wallet.readyState === "Loadable"
      ) {
        detected.push(wallet);
      } else if (wallet.readyState === "NotDetected") {
        undetected.push(wallet);
      }
    }
    return [detected, undetected];
  }, [aptosWallets]);

  const handleDisconnect = useCallback(() => {
    disconnect();
    updateSignature("");
  }, [disconnect, updateSignature]);

  useEffect(() => {
    if (connected) {
      const isMainnet = detected.some(
        (wallet: any) =>
          wallet.provider?.network?.name?.toLowerCase() === "mainnet"
      );
      const isTestnet = detected.some(
        (wallet: any) =>
          wallet.provider?.network?.name?.toLowerCase() === "testnet"
      );

      setWalletNetwork(isMainnet || isTestnet);
      if (
        network?.name?.toLowerCase() === "mainnet" ||
        network?.name?.toLowerCase() === "testnet"
      ) {
        updateWalletConnect(false);

        const payload = {
          message: "Kana Paymaster Dashboard Login",
          nonce: "Kanalabs",
        };

        const fetchBalance = async () => {
          if (account?.address) {
            // updateAddress(account?.address as any);
            // updatePublicKey(account?.publicKey as any);
            // setAddress(account?.address as string);
            try {
              updateBalance(
                await fetchBalanceFromUser(
                  account?.address,
                  network?.name?.toLowerCase()
                )
              );
            } catch (error: any) {
              toast.error("Error while fetching balance");
            }
          }
        };
        fetchBalance();

        let isSignatureProcessed = false;

        signMessage(payload)
          .then((signature: any) => {
            if (isSignatureProcessed) return; // Prevent duplicate processing
            isSignatureProcessed = true;

            updateSignature(signature?.signature);

            const rawSignature = signature?.signature?.data?.data;
            if (rawSignature) {
              const hexSignature = uint8ArrayToHex(rawSignature);
              // Update with formatted signature
              updateSignature(hexSignature);
              updatePublicKey(account?.publicKey);
              updateAddress(account?.address);
            } else {
              console.error("Invalid signature data");
            }
          })
          .catch(() => {
            disconnect();
          });
      } else {
        toast.warning("Switch to Mainnet or Testnet");
        disconnect();
      }
    }
  }, [
    connected,
    detected,
    handleDisconnect,
    navigate,
    setWalletNetwork,
    updateWalletConnect,
    network?.name,
    disconnect,
    signMessage,
    updateSignature,
    account?.address,
    account?.publicKey,
    address,
  ]);

  const handleClickAway = () => {
    updateWalletConnect(false);
  };

  const panelClasses = `w-full h-[11rem] bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] rounded-b-[1rem] overflow-y-scroll`;

  return (
    <div className="fixed z-[4] inset-0 h-auto w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[2rem] flex flex-row justify-center items-center sm:items-end xd:items-end">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="2xl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[25rem] xd:w-[25rem] max-sm:w-[21rem] h-[15rem] bg-[rgba(23,24,26,0.50)] border-[rgba(255,255,255,0.10)] backdrop-blur-[54.36563491821289px] bg-transparent border-2 rounded-[1rem]">
          <div className="flex justify-between p-[3%_5%] bg-[rgba(255,255,255,0.06)] rounded-t-[1rem]">
            <div className="text-white font-[500] text-[1.25rem]">
              {t("Connect Wallet")}
            </div>
            <img
              src={close}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                updateWalletConnect(false);
                updateSignature("");
              }}
            />
          </div>
  
          <div className="flex justify-center outline-none h-full">
            <div className={panelClasses}>
              <ul className="flex flex-col">
                {detected
                  .filter(
                    (item: any) =>
                      item.name === "Petra" || item.name === "Nightly"
                  )
                  .map((item: any, index: any) => (
                    <div
                      className="flex items-center p-4 rounded-[1rem] hover:bg-[#f5fffa0f] cursor-pointer"
                      onClick={() => connect(item?.name)}
                      key={index}
                    >
                      <img className="w-7 h-7" src={item?.icon} alt={item?.name} />
                      <div className="font-inter font-bold leading-5 mx-6 text-lg text-white">
                        {item?.name}
                      </div>
                      {connected && (
                        <div
                          className="flex-1 text-right text-white"
                          onClick={() => disconnect()}
                        ></div>
                      )}
                    </div>
                  ))}
  
                {undetected
                  .filter(
                    (item: any) =>
                      item.name === "Petra" || item.name === "Nightly"
                  )
                  .map((item: any, index: any) => (
                    <a
                      key={index}
                      href={
                        WALLET_INSTALL_LINKS[item?.name] ||
                        "https://aptos.dev/ecosystem/wallets"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center p-4 rounded-[1rem] hover:bg-[#f5fffa0f] cursor-pointer"
                    >
                      <img className="w-7 h-7" src={item?.icon} alt={item?.name} />
                      <div className="font-inter font-bold leading-5 mx-6 text-lg text-white">
                        {item?.name}
                      </div>
                      <span className="text-blue-400 underline">Install</span>
                    </a>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
  
};

export default ConnectWallet;
