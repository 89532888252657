import Pagination from "pagination/Pagination";
import React from "react";

const SkeletonLoader = ({ currentPage, totalPage }: { currentPage: number, totalPage: number }) => {
  return (
    <div>
      <div className="">
        <table className=" w-full">
          <thead>
            <tr className="text-[#777879] text-[1rem] font-[400] gap-[1rem] opacity-0.5 bg-[#111213] h-[3.25rem] w-full">
              <th className="w-[6.938remrem] px-4">Version</th>
              <th className=" w-[17.391rem] px-4">Sender Address</th>
              <th className=" w-[17.391rem]">Fee Payer</th>
              <th className=" w-[17.391rem]">Function</th>
              <th className=" w-[17.391rem] pl-28">Gas Used</th>
            </tr>
          </thead>
        </table>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
        <div className="bg-[#777879] h-4 w-[4rem] rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-10 rounded"></div>
        <div className="bg-[#777879] h-4 w-[10rem] mx-12 rounded"></div>
        <div className="bg-[#777879] h-4 w-[13rem] mx-14 rounded"></div>
        <div className="bg-[#777879] h-4 w-[5rem] ml-28 rounded"></div>
        </div>
        <div className=" mb-8">
          <div className=" flex justify-center xxl:pt-6 xl:pt-6 lg:pt-6 md:pt-6 sm:pt-3.5 xd:pt-3.5 text-[#697586] font-manrope font-[500] text-[0.875rem]">
            Showing result {currentPage} of {totalPage}
          </div>
          <Pagination
            currentPage={0}
            totalPages={0}
            onPageChange={function (page: number): void {
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;