import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "components/Register";
import Deposit from "components/Deposit";
import UsersWhitelist from "components/UserWhitelist";
import FeePayerTransactionHistory from "components/FeePayerTransactionHistory";
import Dashboard from "components/DashBoard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundPage from "components/NotFoundPage";
import FunctionsWhitelist from "components/FunctionsWhitelist";
import AdminDashboard from "components/AdminDashboard";
import AllDApps from "components/AllDApps";
import DappDetails from "components/DappDetails";
import Grant from "components/Grant";
import Webflow from "components/webflow";
import ParticularDappDetails from "components/particularDappDetails";

function App() {
  const handleCloseDepositModel = () => { };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Webflow />} />
          <Route path="/deposit" element={<Deposit handleCloseDepositModel={handleCloseDepositModel} />} />
          <Route path="/users-whitelist" element={<UsersWhitelist />} />
          <Route path="/all-dapps" element={<AllDApps />} />
          <Route path="/transactions-history" element={<FeePayerTransactionHistory />} />
          <Route path="/functions-whitelist" element={<FunctionsWhitelist />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/dapp-Information" element={<DappDetails />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/grant" element={<Grant />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dapp" element={<ParticularDappDetails />} /> 
        </Routes>
        <ToastContainer position="top-right" autoClose={3000} />
      </BrowserRouter>

    </>
  );
}

export default App;
