import axios from "axios";
import axiosRetry from "axios-retry";
import { toast } from "react-toastify";
import dotenv from "dotenv";
import { getAptosRpcEndPoint } from "./sign";
import { AccountAddress } from "@aptos-labs/ts-sdk";
import { chingari, Eragon, Evolv, Floc, Kanaspot, Slime, Wapal, TeviCorp, SantaBrowser } from "./constants";
dotenv.config();

export const handleTotalTxnsGasUsedData = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getGasUsedAndTxns`
  );
  return response.data.overallData;
};

export const handleTopFunctionData = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getTopFunctions`
    );
    return {
      data: response.data.data,
      overallFunctionCount: response.data.overallFunctionCount,
    };
  } catch (error) {
    console.error("Error fetching top function data:", error);
    throw error;
  }
};

export const handleParticularUserData = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getGasUsedAndTxns`
  );
  return response.data.data;
};

export const handleWeeklyTxns = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getDailyTransactions?range=weekly`
  );
  return response.data.data;
};

export const handleDappInformation = async (feePayerAddress: any) => {
  const response = await axios.get(
    `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getTopFunctions?feePayerAddress=${feePayerAddress}`
  );
  return response.data.data;
};
export const handleOverallfunctionCountData = async (feePayerAddress: any) => {
  const response = await axios.get(
    `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getTopFunctions?feePayerAddress=${feePayerAddress}`
  );
  return response.data.overallFunctionCount;
};
export const handleParticularFeePayerData = async (feePayerAddress: any) => {
  const response = await axios.get(
    `${process.env.REACT_APP_PAYMASTER_BASE_URL}/getGasUsedAndTxns?feePayerAddress=${feePayerAddress}`
  );
  return response.data.overallData;
};

export const copyKey = (value: any) => {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  toast.success("Key copied to clipboard", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const copyAddress = (value: any) => {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  toast.success("Address is copied ", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const addressSlice = (value: string) => {
  const addressSliced = `${value?.slice(0, 9)}...${value?.slice(-9)}`;
  return addressSliced;
};

export const apiKeySlice = (value: string) => {
  const apiKeySliced = `${value.slice(-4)}`;
  return apiKeySliced;
};

export const fetchAccountModules = async (userAddress: any, apikey: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_MAINNET_URL}/accounts/${userAddress}/modules?limit=1000`
    );
    if (
      response.status === 200 &&
      response.data[0].abi.exposed_functions.length > 0
    ) {
      const isEntryFunctions = response.data;
      return isEntryFunctions;
    }
  } catch (error: any) {
    toast.error(error);
  }
};

export const enableFeepayerAccountState = async (
  userAddress: any,
  apikey: any
) => {
  const dataparams = {
    feepayer_address: userAddress,
    status: "true",
  };
  const modifyUserState = `${process.env.REACT_APP_BASE_URL}/modifyFeepayerState`;
  const response = await axios.get(modifyUserState, {
    params: dataparams,
    headers: {
      "Content-Type": "application/json",
      "api-key": apikey as string,
    },
  });
  return response;
};

export const disableFeepayerAccountState = async (
  userAddress: any,
  apikey: any
) => {
  const dataparams = {
    feepayer_address: userAddress,
    status: "false",
  };
  const modifyUserState = `${process.env.REACT_APP_BASE_URL}/modifyFeepayerState`;
  const response = await axios.get(modifyUserState, {
    params: dataparams,
    headers: {
      "Content-Type": "application/json",
      "api-key": apikey as string,
    },
  });
  return response;
};
export const modifyAllowAllScriptsStateToInActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_scripts: false,
    };
    const allowAllScripts = `${process.env.REACT_APP_BASE_URL}/allowScriptFunctions`;
    const response = await axios.get(allowAllScripts, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });

    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const modifyAllowAllScriptsStateToActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_scripts: true,
    };
    const allowAllScripts = `${process.env.REACT_APP_BASE_URL}/allowScriptFunctions`;
    const response = await axios.get(allowAllScripts, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });

    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const modifyAllowAllFunctionsStateToActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_all_functions: true,
    };
    const allowAllFunctions = `${process.env.REACT_APP_BASE_URL}/allowAllFunctions`;
    const response = await axios.get(allowAllFunctions, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const modifyAllowAllFunctionsStateToInActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_all_functions: false,
    };
    const allowAllFunctions = `${process.env.REACT_APP_BASE_URL}/allowAllFunctions`;
    const response = await axios.get(allowAllFunctions, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};
export const getWhiteListedFunctionsList = async (apikey: any) => {
  try {
    const addFunctionWhitelistEndpoint = `${process.env.REACT_APP_BASE_URL}/getWhitelistedFunctions`;
    const response = await axios.get(addFunctionWhitelistEndpoint, {
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      return response.data.userfunctions;
    }
  } catch (error: any) {
    return error.response.data.message;
  }
};
export const addFunctions = async (
  apikey: any,
  moduleName: any,
  functionName: any,
  moduleAddress: any
) => {
  try {
    const addFunctionWhitelistEndpoint = `${process.env.REACT_APP_BASE_URL}/addWhitelistFunctions`;

    const functionsToAdd = [
      {
        module_name: moduleName,
        function_name: functionName,
        module_address: moduleAddress,
      },
    ];

    const response = await axios.post(
      addFunctionWhitelistEndpoint,
      {
        functions: functionsToAdd,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": apikey as string,
        },
      }
    );
    if (response.status === 201 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.error);
  }
};

export const modifyFunctions = async (
  apikey: any,
  moduleName: any,
  functionName: any,
  moduleAddress: any,
  status: any
) => {
  try {
    const modifyFunctionStateEndpoint = `${process.env.REACT_APP_BASE_URL}/modifyFunctionState`;
    const functionsToModify = {
      module_name: moduleName,
      function_name: functionName,
      module_address: moduleAddress,
      status: status,
    };
    const response = await axios.post(
      modifyFunctionStateEndpoint,
      functionsToModify,
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": apikey as string,
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const handleDappDetails = async (
  address: any,
  signature: any,
  publicKey: any
) => {
  const url = `${process.env.REACT_APP_BASE_URL}/getDappDetails`;
  const data = {
    address: address,
    signature: signature,
    publicKey: publicKey,
  };
  const response = await axios.post(url, data);
  return response;
};

let cachedUSDPrice = 0; 
let lastFetchTime = 0; 
const CACHE_DURATION = 60 * 1000; 

export const fetchUSDPriceOfAptos = async (aptosAmount: number): Promise<string> => {
  const currentTime = Date.now();
  if (currentTime - lastFetchTime < CACHE_DURATION && cachedUSDPrice > 0) {
    const totalUSDPrice = cachedUSDPrice * aptosAmount;
    return totalUSDPrice.toFixed(1);
  }
  try {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=aptos&vs_currencies=usd"
    );
    cachedUSDPrice = response.data.aptos.usd; 
    lastFetchTime = currentTime; 
    const totalUSDPrice = cachedUSDPrice * aptosAmount;
    return totalUSDPrice.toFixed(1);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429) {
      if (cachedUSDPrice > 0) {
        const totalUSDPrice = cachedUSDPrice * aptosAmount;
        return totalUSDPrice.toFixed(1);
      }
      throw new Error("Rate limit exceeded and no cached value available.");
    }
    throw new Error("Failed to fetch the USD price of Aptos.");
  }
};


export const sender = (sender: any) => {
  if (!sender) return "";
  const slicedSender = `${sender.slice(0, 8)}...${sender.slice(-8)}`;
  return slicedSender;
};

export const signature = (feePayerAddress: any) => {
  const truncatedFeePayerAddress = feePayerAddress?.slice(0, 8);
  const truncatedHash = feePayerAddress?.slice(-8);
  return `${truncatedFeePayerAddress}...${truncatedHash}`;
};

export const payloadFunction = (functionString: any) => {
  if (!functionString) return "";
  const parts = functionString.split("::");
  if (parts.length < 2) return functionString;
  return parts.slice(-2).join("::");
};

export const argumentDetails = (argument: any) => {
  if (!argument) return "";
  const slicedArgument = `${argument.slice(0, 8)}...${argument.slice(-8)}`;
  return slicedArgument;
};

export const removePrefix = (functionString: any) => {
  if (!functionString) return "";
  return functionString.replace("0x1::", "");
};

export const depositDetails = async (
  feePayerAddress: string,
  accountAddress: string,
  amount: any,
  hash: string,
  network: string
) => {
  try {
    const depositEndpoint = `${process.env.REACT_APP_BASE_URL}/updateDepositDetails?feePayerAddress=${feePayerAddress}&accountAddress=${accountAddress}&amount=${amount}&hash=${hash}&network=${network}`;
    const response = await axios.get(depositEndpoint);
    return response.data.success;
  } catch (err) {
    return err;
  }
};

export const withdrawDetails = async (
  network: string,
  signature: string,
  accountAddress: string,
  amount: any,
  publicKey: string,
  feePayerAddress: string
) => {
  try {
    let withdrawEndpoint = `${process.env.REACT_APP_BASE_URL}/withdraw?accountAddress=${accountAddress}&amount=${amount}&publicKey=${publicKey}&signature=${signature}&feePayerAddress=${feePayerAddress}`;

    const response = await axios.get(withdrawEndpoint, {
      headers: {
        "Content-Type": "application/json",
        network: network as string,
      },
    });
    if (response.data.success) {
      return response.data;
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (err: any) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};

export const checkAdmin = async (
  accountAddress: string,
  signature: string,
  publicKey: string
) => {
  const checkAdminEndpoint = `${process.env.REACT_APP_PAYMASTER_BASE_URL}/checkAdmin`;
  try {
    const requestBody = {
      accountAddress: accountAddress,
      signature: signature,
      publicKey: publicKey,
    }; // Add your request body here if needed
    const response = await axios.post(checkAdminEndpoint, requestBody);
    return response.data.success;
  } catch (error) {
    return error;
  }
};

export const addmodule = async (apikey: any, moduleAddress: any) => {
  try {
    const addFunctionWhitelistEndpoint = `${process.env.REACT_APP_BASE_URL}/addModuleWhitelistFunctions`;

    const moduleToAdd = {
      functions: [
        {
          module_address: moduleAddress,
        },
      ],
    };

    const response = await axios.post(
      addFunctionWhitelistEndpoint,
      moduleToAdd,
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": apikey as string,
        },
      }
    );

    if (response.status === 201 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.error);
  }
};

export const checkAptBalance = async (address: string, network: string) => {
  try {
    const aptos = getAptosRpcEndPoint(network);
    const accountAddress = AccountAddress.from(address);
    const aptosBalance = await aptos.getAccountAPTAmount({
      accountAddress: accountAddress,
    });
    return aptosBalance;
  } catch (error: any) {
    throw error;
  }
};
// export const checkAptBalancesForSlime = async (
//   address: string,
//   network: string
// ) => {
//   try {
//     const aptos = getAptosRpcEndPoint(network);
//     const accountAddress = AccountAddress.from(address);
//     const aptosBalance = await aptos.getAccountAPTAmount({
//       accountAddress: accountAddress,
//     });
//     return aptosBalance;
//   } catch (error: any) {
//     throw error;
//   }
// };

export async function checkAptBalanceForDappFeepayer(address: string, maxRetries = 3, delay = 20000) {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const aptos: any = getAptosRpcEndPoint("mainnet");
      const accountAddress = AccountAddress.from(address);
      const aptosBalance = await aptos.getAccountAPTAmount({
        accountAddress: accountAddress,
      });
      return aptosBalance;
    } catch (error: any) {
      if (attempt === maxRetries) {
        throw new Error("Max retries reached. Request failed.");
      }
      await new Promise((resolve) => setTimeout(resolve, delay * attempt));
    }
  }
}

export const getWithdrawalAmount = async (
  feePayerAddress: string,
  accountAddress: string
) => {
  try {
    let getWithdrawalAmountEndpoint: any = `${process.env.REACT_APP_BASE_URL}/getWithdrawalAmount?feePayerAddress=${feePayerAddress}&accountAddress=${accountAddress}`;
    const response = await axios.get(getWithdrawalAmountEndpoint);

    if (response.data.success) {
      return response.data;
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (err: any) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};

export const getWhiteListedModulesList = async (apikey: any) => {
  try {
    const addModulesWhitelistEndpoint = `${process.env.REACT_APP_BASE_URL}/getWhitelistedModules`;
    const response = await axios.get(addModulesWhitelistEndpoint, {
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      return response.data.userfunctions;
    }
  } catch (error: any) {
    return error.response.data.message;
  }
};

export const modifymodules = async (
  apikey: any,
  moduleAddress: any,
  status: any
) => {
  try {
    const modifyModulesStateEndpoint = `${process.env.REACT_APP_BASE_URL}/modifyModuleState`;
    const functionsToModify = {
      module_address: moduleAddress,
      status: status,
    };
    const response = await axios.post(
      modifyModulesStateEndpoint,
      functionsToModify,
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": apikey as string,
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 2000,
  retryCondition: (error: any) =>
    error.code === "ECONNABORTED" || error.response?.status >= 500,
});

// Fetch GraphQL data
async function fetchGraphQL(
  operationsDoc: any,
  operationName: any,
  variables: any
) {
  try {
    const response = await axios.post(
      "https://api.mainnet.aptoslabs.com/v1/graphql",
      {
        query: operationsDoc,
        variables: variables,
        operationName: operationName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 30000, // Increased timeout to 30 seconds
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(
      `GraphQL query failed for variables ${JSON.stringify(variables)}:`,
      error.message
    );
    throw error;
  }
}

const operationsDoc = `
  query GetBalance($ownerAddress: String!) {
    current_fungible_asset_balances(
      where: {owner_address: {_eq: $ownerAddress}, asset_type: {_eq: "0x1::aptos_coin::AptosCoin"}}
    ) {
      amount_v1
    }
  }
`;

async function fetchBalance(ownerAddress: string) {
  try {
    const { errors, data } = await fetchGraphQL(operationsDoc, "GetBalance", {
      ownerAddress,
    });

    if (errors) {
      console.error("GraphQL Errors:", errors);
      return null;
    }

    const balance = data?.current_fungible_asset_balances[0]?.amount_v1 || 0;
    return balance;
  } catch (error: any) {
    console.error(
      `Error during fetchBalance for ${ownerAddress}:`,
      error.message
    );
    return null;
  }
}

// All addresses
export const addresses = {
  Kanaspot,
  Wapal,
  Slime,
  Eragon,
  Evolv,
  chingari,
  Floc,
  SantaBrowser,
  TeviCorp
};

// Fetch balances for all addresses
export async function fetchBalancesForAllAddresses() {
  const results: Record<string, number | null> = {};

  for (const [name, address] of Object.entries(addresses)) {
    try {
      const balance = await fetchBalance(address);
      results[name] = balance;
    } catch (error: any) {
      console.error(`Failed to fetch balance for ${name}:`, error.message);
      results[name] = null;
    }
  }
  return results;
}

export const checkDapp = async (
  accountAddress: string,
  signature: string,
  publicKey: string
) => {
  const checkAdminEndpoint = `${process.env.REACT_APP_PAYMASTER_BASE_URL}/checkDapp`;
  try {
    const requestBody = {
      accountAddress: accountAddress,
      signature: signature,
      publicKey: publicKey,
    }; // Add your request body here if needed
    const response = await axios.post(checkAdminEndpoint, requestBody);
    return {
      status: response?.data?.success,
      feePayerAddress: response?.data?.feePayerAddress
    };
  } catch (error) {
    return error;
  }
};

export function uint8ArrayToHex(uint8Array: any) {
  return Array.from(uint8Array)
    .map((byte: any) => byte.toString(16).padStart(2, "0"))
    .join("");
}