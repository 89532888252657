import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { handleWeeklyTxns } from "utils/helpers";

const TinyBarChart = () => {
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      try {
        const totalTinyBarChart: any = await handleWeeklyTxns();
        setResponse(
          totalTinyBarChart.filter((item: any) =>
            [
              "chingari",
              "wapal",
              "floc",
              "eragon",
              "slime",
              "evolv",
              "santabrowser",
              "tevicorp",
              "rewardyprod"
            ].includes(item.feePayer_name.toLowerCase())
          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const weekDataMap: Map<string, { [key: string]: number }> = new Map();

  response?.forEach((item: any) => {
    const weekStart = new Date(item.from_date);
    const weekEnd = new Date(item.to_date);
    const weekKey = formatDate(weekStart) + " - " + formatDate(weekEnd);

    const formattedFeePayerName = item.feePayer_name.toLowerCase(); 

    if (!weekDataMap.has(weekKey)) {
      weekDataMap.set(weekKey, {
        chingari: 0,
        wapal: 0,
        floc: 0,
        eragon: 0,
        slime: 0,
        evolv: 0,
        santabrowser: 0,
        tevicorp: 0,
        rewardyprod: 0
      });
    }

    const weekData = weekDataMap.get(weekKey)!;
    weekData[formattedFeePayerName] =
      (weekData[formattedFeePayerName] || 0) + item.totaltxns;
  });

  const labels: string[] = [];
  const chingariTxns: number[] = [];
  const wapalSpotTxns: number[] = [];
  const flocSpotTxns: number[] = [];
  const eragonSpotTxns: number[] = [];
  const slimeSpotTxns: number[] = [];
  const evolvSpotTxns: number[] = [];
  const santaBrowserSpotTxns: number[] = [];
  const teviCorpSpotTxns: number[] = [];
  const rewardyProdSpotTxns: number[] = [];

  weekDataMap.forEach((value, key) => {
    labels.push(key);
    chingariTxns.push(value["chingari"] || 0);
    wapalSpotTxns.push(value["wapal"] || 0);
    flocSpotTxns.push(value["floc"] || 0);
    eragonSpotTxns.push(value["eragon"] || 0);
    slimeSpotTxns.push(value["slime"] || 0);
    evolvSpotTxns.push(value["evolv"] || 0);
    santaBrowserSpotTxns.push(value["santabrowser"] || 0);
    teviCorpSpotTxns.push(value["tevicorp"] || 0);
    rewardyProdSpotTxns.push(value["tevicorp"] || 0);
  });

  const data = {
    labels,
    datasets: [
      {
        label: "CHINGARI",
        data: chingariTxns,
        backgroundColor: "#00F9A9",
        barThickness: 15,
      },
      {
        label: "WAPAL",
        data: wapalSpotTxns,
        backgroundColor: "#c66b27",
        barThickness: 15,
      },
      {
        label: "FLOC",
        data: flocSpotTxns,
        backgroundColor: "#efe3e3",
        barThickness: 15,
      },
      {
        label: "ERAGON",
        data: eragonSpotTxns,
        backgroundColor: "#431a69",
        barThickness: 15,
      },
      {
        label: "SLIME",
        data: slimeSpotTxns,
        backgroundColor: "#F79009",
        barThickness: 15,
      },
      {
        label: "EVOLV",
        data: evolvSpotTxns,
        backgroundColor: "#F04438",
        barThickness: 15,
      },
      {
        label: "SANTABROWSER",
        data: santaBrowserSpotTxns,
        backgroundColor: "#7AA0E0",
        barThickness: 15,
      },
      {
        label: "TEVICORP",
        data: teviCorpSpotTxns,
        backgroundColor: "#FF0000",
        barThickness: 15,
      },
      {
        label: "REWARDYPROD",
        data: teviCorpSpotTxns,
        backgroundColor: "#8A2BE2",
        barThickness: 15,
      },
    ],
  };

  return (
    <div className="tiny-bar-chart-container w-full">
      <Bar data={data} className="tiny-bar-chart" />
    </div>
  );
};

export default TinyBarChart;

function formatDate(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
