import { AptosAccount, AptosClient } from "aptos";
import { Aptos, AptosConfig, ClientConfig, Network } from "@aptos-labs/ts-sdk";
import { DuneClient } from "@duneanalytics/client-sdk";
import dotenv from "dotenv";
dotenv.config();

export const FAUCET_URL = "https://faucet.net.aptoslabs.com";
export const NODE_URL = "https://fullnode.mainnet.aptoslabs.com/v1";
export const aptosClient = new AptosClient(NODE_URL);

export let Kanaspot =
  "0x71399486d1990269d535fe6269f184c86ae218f15bb473d70848e14230501a63";
export let chingari =
  "0x820931c36d84f8f5b44d4b37701c00b29e55f714eec2165c47508bf57d18a42c";
export let Wapal =
  "0x8fae02f17d07d4761579a77fdc91f1046d4789e66c42d05865e58288b37a2de3";
export let Floc =
  "0xfd1f4cccbaf4b447dad1981e8c816a14df9e4db6b89baeb15e5f97d5385309ee";
export let Eragon =
  "0x6abfaddafe5c4b6fbd13d8cbdaa874f1de2a887f7fe73795f0fa19d488f940b8";
export let Slime =
  "0xe6571603d74a7d07a1af0b5c917efb12f8bde3ce8bfddbb3b3ae45982d827ff8";
export let Evolv =
  "0x681c34b64ff752241b6d7defc0ad6ca60b42d7b0c5a491fb89103f73ede734fe";
export let SantaBrowser =
  "0xd39a88a7781d57b908c10562643c53565d14e1815cbc4ed54bb5b0b6603d41c1";
export let TeviCorp =
  "0xb83f4fb7931fca94019d02136e956aa2779ce08729cc7fc0886100329b8ad41b";
export let RewardyProd =
  "0x9b9704cd20910e78bcbbbd602747af80062584718668825e991670c574132ddb";
const mainnetApikey = process.env.REACT_APP_MAINNET_APIKEY as string;
const testnetApikey = process.env.REACT_APP_TESTNET_APIKEY as string;

const clientConfigMainnet: ClientConfig = {
  API_KEY: mainnetApikey,
};
const clientConfigTestnet: ClientConfig = {
  API_KEY: testnetApikey,
};

const mainnetConfig = new AptosConfig({
  indexer: "https://api.mainnet.aptoslabs.com/v1",
  clientConfig: clientConfigMainnet,
  network: Network.MAINNET,
});

const testnetConfig = new AptosConfig({
  indexer: "https://api.testnet.aptoslabs.com/v1 ",
  clientConfig: clientConfigTestnet,
  network: Network.TESTNET,
});

// Aptos SDK instance
// Mainnet
export const aptosMainnet = new Aptos(mainnetConfig);
// Testnet
export const aptosTestnet = new Aptos(testnetConfig);

export const feePayerObj = {
  address: "0xae8699a1788d18e44ca23923cc65ed7c3d1cb9c4d37d91fbab94254006009a79",
  publicKeyHex: process.env.REACT_APP_FEE_PAYER_PUB_KEY as string,
  privateKeyHex: process.env.REACT_APP_FEE_PAYER_PRIVATE_KEY as string,
};

export const feePayerAccount = AptosAccount.fromAptosAccountObject(feePayerObj);
export const feePayerAddress = feePayerAccount.address().hex();
export const minReqAllowance = 10000000;
export const duneClient = new DuneClient(
  process.env.REACT_APP_DUNE_URL as string
);

export const AssignSignerAddress: { [key: string]: string } = {
  Chingari:
    "0x32605c816ce06b811d17f9e1158ff7c8252e36ecc03582da8bd149d19ed3ebde",
  KanaSpot:
    "0x8bc1265438bb51a84741c401ebd2fc652ebb0b9aa0dda56875365000f7efdee3",
  Wapal: "0xc949c6282f47572cd023ba0b10b2ad7272043ef68cc27665a268ab95b346fe2f",
  Floc: "0x0d6db1f643de21188f8b389bed1c2ffb114695004e91d6d149a8d5e4b9c1dee3",
  Eragon: "0x563c8f1f727ddd872bf4c908eaf31a2caa6b156566bbc374b11758637baa9135",
  Evolv: "0xe074e476e373dcebbcf3f82bbc0c25c3d94137f3ca261472ef147feeb78ab3ba",
  Slime: "0xe012822f44b2765789a8b50cded4d71874a264d55b28eb61cfbe38abb1c6dd8b",
  SantaBrowser:
    "0x8eecf93e54c27e7dcc0b3b42ae08bc22cfa02d6262e1a72981f27443f446279f",
  TeviCorp:
    "0x5a0ad9e31a2f452504429b6f7073cb325994c2c66204f5deb8e0561a9e950c3c",
  RewardyProd:
    "0x6425adc99a362c01a435a40310b3b1ef3bc8a77a07d8146106728e9020edc069",
};
