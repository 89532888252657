import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { handleParticularUserData } from "utils/helpers";

const DoughnutChart: React.FC = () => {
  const [totalTxnCountChingari, setTotalTxnCountChingari] = useState<number>(0);
  const [signerAddressChingari, setSignerAddressChingari] =
    useState<string>("");
  const [totalTxnCountKanaSpot, setTotalTxnCountKanaSpot] = useState<number>(0);
  const [signerAddressKanaSpot, setSignerAddressKanaSpot] =
    useState<string>("");
  const [totalTxnCountWapal, setTotalTxnCountWapal] = useState<number>(0);
  const [signerAddressWapal, setSignerAddressWapal] = useState<string>("");
  const [totalTxnCountEragon, setTotalTxnCountEragaon] = useState<number>(0);
  const [signerAddressEragon, setSignerAddressEragon] = useState<string>("");
  const [totalTxnCountFloc, setTotalTxnCountFloc] = useState<number>(0);
  const [signerAddressFloc, setSignerAddressFloc] = useState<string>("");
  const [totalTxnCountSlime, setTotalTxnCountSlime] = useState<number>(0);
  const [signerAddressSlime, setSignerAddressSlime] = useState<string>("");
  const [totalTxnCountEvolv, setTotalTxnCountEvolv] = useState<number>(0);
  const [signerAddressEvolv, setSignerAddressEvolv] = useState<string>("");
  const [totalTxnCountSantaBrowser, setTotalTxnCountSantaBrowser] = useState<number>(0);
  const [signerAddressSantaBrowser, setSignerAddressSantaBrowser] = useState<string>("");
  const [totalTxnCountTeviCorp, setTotalTxnCountTeviCorp] = useState<number>(0);
  const [signerAddressTeviCorp, setSignerAddressTeviCorp] = useState<string>("");
  const [totalTxnCountRewardyProd, setTotalTxnCountRewardyProd] = useState<number>(0);
  const [signerAddressRewardyProd, setSignerAddressRewardyProd] = useState<string>("");

  useEffect(() => {
    async function fetchData() {
      try {
        const totalDonutChartCount: any = await handleParticularUserData();
        setTotalTxnCountChingari(
          totalDonutChartCount?.[0]?.total_transactions_till_now || 0
        );
        setTotalTxnCountWapal(
          totalDonutChartCount?.[1]?.total_transactions_till_now || 0
        );
        setTotalTxnCountKanaSpot(
          totalDonutChartCount?.[2]?.total_transactions_till_now || 0
        );
        setTotalTxnCountEragaon(
          totalDonutChartCount?.[4]?.total_transactions_till_now || 0
        );
        setTotalTxnCountFloc(
          totalDonutChartCount?.[5]?.total_transactions_till_now || 0
        );
        setTotalTxnCountSlime(
          totalDonutChartCount?.[6]?.total_transactions_till_now || 0
        );
        setTotalTxnCountEvolv(
          totalDonutChartCount?.[7]?.total_transactions_till_now || 0
        );
        setTotalTxnCountTeviCorp(
          totalDonutChartCount?.[8]?.total_transactions_till_now || 0
        );
        setTotalTxnCountRewardyProd(
          totalDonutChartCount?.[9]?.total_transactions_till_now || 0
        );
        setTotalTxnCountSantaBrowser(
          totalDonutChartCount?.[10]?.total_transactions_till_now || 0
        );
        
        setSignerAddressChingari(
          totalDonutChartCount?.[0]?.feePayer_name || ""
        );
        setSignerAddressWapal(totalDonutChartCount?.[1]?.feePayer_name || "");
        setSignerAddressKanaSpot(
          totalDonutChartCount?.[2]?.feePayer_name || ""
        );
        setSignerAddressEragon(totalDonutChartCount?.[4]?.feePayer_name || "");
        setSignerAddressFloc(totalDonutChartCount?.[5]?.feePayer_name || "");
        setSignerAddressSlime(totalDonutChartCount?.[6]?.feePayer_name || "");
        setSignerAddressEvolv(totalDonutChartCount?.[7]?.feePayer_name || "");
        setSignerAddressTeviCorp(totalDonutChartCount?.[8]?.feePayer_name || "");
        setSignerAddressRewardyProd(totalDonutChartCount?.[9]?.feePayer_name || "");
        setSignerAddressSantaBrowser(totalDonutChartCount?.[10]?.feePayer_name || "");
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart<
    "doughnut",
    number[],
    string
  > | null>(null);
  useEffect(() => {
    if (chart) {
      chart.destroy(); // Destroy existing chart instance if it exists
    }
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const newChartInstance = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "CHINGARI",
              "WAPAL",
              "KANASPOT",
              "ERAGON",
              "FLOC",
              "SLIME",
              "EVOLV",
              "SANTA BROWSER",
              "TEVI CORP",
              "REWARDY PROD"
            ],
            datasets: [
              {
                data: [
                  totalTxnCountChingari,
                  totalTxnCountWapal,
                  totalTxnCountKanaSpot,
                  totalTxnCountEragon,
                  totalTxnCountFloc,
                  totalTxnCountSlime,
                  totalTxnCountEvolv,
                  totalTxnCountSantaBrowser,
                  totalTxnCountTeviCorp,
                  totalTxnCountRewardyProd
                ],
                backgroundColor: [
                  "#00F9A9",
                  "#c66b27",
                  "#0E9384",
                  "#efe3e3",
                  "#FDB022",
                  "#431a69",
                  "#F79009",
                  "#7AA0E0",
                  "#FF0000",
                  "#8A2BE2"
                ],
                borderColor: [
                  "#00F9A9",
                  "#c66b27",
                  "#0E9384",
                  "#efe3e3",
                  "#FDB022",
                  "#431a69",
                  "#F79009",
                  "#7AA0E0",
                  "#FF0000",
                  "#8A2BE2"
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
        setChart(newChartInstance);
      }
    }
  }, [
    totalTxnCountChingari,
    totalTxnCountWapal,
    totalTxnCountKanaSpot,
    totalTxnCountEragon,
    totalTxnCountFloc,
    totalTxnCountSlime,
    totalTxnCountEvolv,
    totalTxnCountSantaBrowser,
    totalTxnCountTeviCorp,
    totalTxnCountRewardyProd
  ]);

  const handleLabelClick = (index: number) => {
    if (chart) {
      const newData = [...chart.data.datasets[0].data];
      newData[index] += 10;
      chart.data.datasets[0].data = newData;
      chart.update();
    }
  };

  return (
    <div style={{ width: "408px", height: "208px" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default DoughnutChart;
