/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Gastx from "../assets/icons/Gastx.svg";
import Average from "../assets/icons/Averagetx.svg";
import Hashtx from "../assets/icons/hash.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useStore } from "../store/index";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import {
  fetchUSDPriceOfAptos,
  handleDappInformation,
  handleParticularFeePayerData,
  handleOverallfunctionCountData,
} from "utils/helpers";
import {
  chingari,
  Eragon,
  Evolv,
  Kanaspot,
  RewardyProd,
  SantaBrowser,
  Slime,
  TeviCorp,
  Wapal,
} from "utils/constants";
import Kanaloader from "../assets/kanaloader.json";
import Lottie from "react-lottie-player";
import OnchainChart from "./OnchainChart";
import { getAptosRpcEndPoint } from "utils/sign";
import { useNavigate } from "react-router-dom";
import AdminDeposit from "./AdminDeposit";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const DappDetails = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("users");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [dappName, setDappName] = useState<any>();
  const [particularFeePayerData, setParticularFeePayerData] = useState<any>();
  const [particularFeePayerTotalgasinUSD, setParticularFeePayerTotalgasinUSD] =
    useState<any>();
  const [overallFunctionData, setOverallFunctionData] = useState<any>();
  const [particularTopFunctions, setParticularTopFunctions] = useState<any[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const { network } = useWallet();

  const {
    updatePopUpIsOpen,
    updateParticularFeepayeraddress,
    particularFeepayeraddress,
    dappUsersPercentage,
    particularFeepayerAddressTxns,
    setChangeHeader
  } = useStore();

  const { connected } = useWallet();

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };
  const openPopup = () => {
    setPopupOpen(!isPopupOpen);
    updatePopUpIsOpen(!isPopupOpen);
  };
  const handleCloseDepositModel = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const handleDapeName = (address: any) => {
      if (address === chingari) {
        setDappName("CHINGARI");
      } else if (address === Kanaspot) {
        setDappName("KANASPOT");
      } else if (address === Wapal) {
        setDappName("WAPAL");
      } else if (address === Eragon) {
        setDappName("ERAGON");
      } else if (address === Slime) {
        setDappName("SLIME");
      } else if (address === Evolv) {
        setDappName("EVOLV");
      } else if (address === SantaBrowser) {
        setDappName("SANTA BROWSER");
      } else if (address === TeviCorp) {
        setDappName("TEVI CORP");
      } else if (address === RewardyProd) {
        setDappName("REWARDY PROD");
      } else {
        setDappName("FLOC");
      }
    };
    handleDapeName(particularFeepayeraddress);
    setChangeHeader(true);
  }, [particularFeepayeraddress]);

  const dappDetail = async (address: any) => {
    if (connected) {
      const response = await handleDappInformation(address);
      const feePayerResponse = await handleParticularFeePayerData(address);
      const totalTranscationGasusedInUSD = await fetchUSDPriceOfAptos(
        feePayerResponse?.overall_gas_cost
      );
      const overallFunctiondataResponse = await handleOverallfunctionCountData(
        address
      );
      updateParticularFeepayeraddress(address);
      setParticularFeePayerData(feePayerResponse);
      setParticularTopFunctions(response);
      setOverallFunctionData(overallFunctiondataResponse);
      setParticularFeePayerTotalgasinUSD(totalTranscationGasusedInUSD);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (connected) {
      dappDetail(particularFeepayeraddress);
      setIsLoading(true);
    }
  }, [connected, particularFeepayeraddress]);

  const recentTransactions = async (network: any) => {
    try {
      const aptos = getAptosRpcEndPoint(network);
      const txns: any = await aptos.getAccountTransactions({
        accountAddress: particularFeepayerAddressTxns,
        options: {
          limit: 6,
        },
      });
      txns.sort(
        (a: any, b: any) => parseInt(b.timestamp) - parseInt(a.timestamp)
      );

      setData(txns);
    } catch (err: any) {
      if (err.error_code === "account_not_found") {
        console.error(`Account not found: ${particularFeepayerAddressTxns}`);
        setData([]);
      }
    }
  };

  useEffect(() => {
    if (connected) {
      recentTransactions(network?.name.toLowerCase());
    }
  }, [network?.name, connected, particularFeepayerAddressTxns]);

  return (
    <div
      className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
    >
      <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
          <Header />
          <div>
            {!connected ? (
              <>{navigate("/dashboard")}</>
            ) : (
              <>
                {isLoading && (
                  <div
                    className={`fixed inset-0 !z-[2]  backdrop-blur-2xl  font-inter  items-center overflow-y-auto  h-[100%] w-[100%] flex flex-col justify-start  bg-[black]   `}
                  >
                    <div className="flex justify-center align-middle pt-60 !bg-transparent">
                      <Lottie
                        className="!bg-transparent"
                        loop
                        animationData={Kanaloader}
                        play
                        style={{ width: 150, height: 150 }}
                      />
                    </div>
                    <div
                      className={`!bg-transparent text-center text-[0.875rem]  align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4 text-[#FFFFFF] `}
                    >
                      This may take few seconds, don’t close the window
                    </div>
                  </div>
                )}
                <div>
                  <div className=" w-full xxl:bg-[#17181A] xl:bg-[#17181A] bxl:bg-[#17181A]  sxl:bg-[#17181A] lg:bg-none md:bg-none sm:bg-none xd:bg-none xxl:border-2 xl:border-2 bxl:border-2 sxl:border-2 lg:border-none md:border-none  border-[#ffffff1a] font-manrope rounded-[1rem] xxl:mt-28 xl:mt-28 bxl:mt-28 sxl:mt-28 lg:mt-[6rem] md:mt-[6rem] sm:mt-[6rem] xd:mt-[6rem] flex xxl:flex-row xl:flex-row bxl:flex-row sxl:flex-row lg:flex-col md:flex-col xd:flex-col sm:flex-col justify-around p-3 gap-[1rem]">
                    <div className="flex xxl:flex-row xl:flex-row bxl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-between w-full">
                      <div className="text-[#FFFFFFCC] font-[800] text-[2.25rem]">
                        {dappName}
                      </div>
                      <div className=" flex  gap-[0.5rem]">
                        <button
                          className="buy-button w-full "
                          onClick={openPopup}
                        >
                          <div className="buy-button-inner">
                            <span className=" text-[#2ED3B7] font-[800]">
                              Deposit/Withdraw
                            </span>
                          </div>
                        </button>

                        {/* <button className="buy-button w-full " onClick={openPopup}>
                <div className="buy-button-inner">
                  <span className=" text-[#2ED3B7] font-[800]">Withdraw</span>
                </div>
              </button> */}
                      </div>
                    </div>
                  </div>
                  <div className=" w-full xxl:bg-[#17181A] xl:bg-[#17181A] bxl:bg-[#17181A]  sxl:bg-[#17181A] lg:bg-none md:bg-none sm:bg-none xd:bg-none xxl:border-2 xl:border-2 bxl:border-2 sxl:border-2 lg:border-none md:border-none  border-[#ffffff1a] font-manrope rounded-[1rem] mt-3 flex xxl:flex-row xl:flex-row bxl:flex-row sxl:flex-row lg:flex-col md:flex-col xd:flex-col sm:flex-col justify-around p-3 gap-[1rem]">
                    <div className=" xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] xxl:w-[23.167rem] xl:w-[23.167rem] bxl:w-[23.167rem] lg:w-full md:w-full sm:w-full xd:w-full rounded-[1rem] p-4  gap-[1rem]">
                      <div className=" flex  p-2">
                        <div className="flex justify-around border-b-2 border-[#ffffff1a] p-2">
                          <OnchainChart />
                          <div className="gap-[0.5rem] px-1">
                            <div className="text-[#A5A5A6] text-[0.75rem] flex ">
                              <span>Total Number of Users</span>
                            </div>
                            <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                              {particularFeePayerData?.overall_users_till_now &&
                                particularFeePayerData?.overall_users_till_now.toLocaleString()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-2 flex">
                        <div className="text-[#A5A5A6] text-[0.75rem] font-[400] flex">
                          <span>{`${dappUsersPercentage}% of the total users are from this Dapp`}</span>
                        </div>
                      </div>
                    </div>
                    <div className=" flex flex-col gap-[0.5rem]">
                      <div className="xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A]  xxl:w-[23.167rem] xl:w-[23.167rem] bxl:w-[23.167rem] lg:w-full md:w-full sm:w-full xd:w-full rounded-[1rem] flex gap-[1rem] p-[3%_6%]">
                        <div className=" w-[3.5rem] h-[3.5rem] rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                          <img src={Hashtx} alt="admin" />
                        </div>
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem] flex">
                            <span>Number of transactions</span>
                          </div>
                          <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                            {particularFeePayerData?.overall_transactions &&
                              particularFeePayerData?.overall_transactions.toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="xxl:bg-[#0c0c0d] xl:bg-[#0c0c0d] bxl:bg-[#0c0c0d] sxl:bg-[#0c0c0d] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A]  xxl:w-[23.167rem] xl:w-[23.167rem] bxl:w-[23.167rem] lg:w-full md:w-full sm:w-full xd:w-full rounded-[1rem] flex gap-[1rem] p-[3%_6%]">
                        <div className=" w-[3.5rem] h-[3.5rem] rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                          <img src={Average} alt="admin" />
                        </div>
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem] flex">
                            <span>Average Gas Used </span>
                          </div>
                          <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                            {particularFeePayerData?.overall_average_cost &&
                              Number(
                                particularFeePayerData?.overall_average_cost
                              ).toFixed(8)}
                            <span className=" text-[1rem] pl-3">APT</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] xxl:w-[23.167rem] xl:w-[23.167rem] bxl:w-[23.167rem] lg:w-full md:w-full sm:w-full xd:w-full rounded-[1rem] p-4 flex gap-[1rem]">
                      <div className=" w-[3.5rem] h-[3.5rem] rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                        <img src={Gastx} alt="admin" />
                      </div>
                      <div>
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem] flex ">
                            <span>Total Gas Used</span>
                          </div>
                          <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                            {particularFeePayerData?.overall_gas_cost &&
                              Number(
                                particularFeePayerData?.overall_gas_cost
                              ).toFixed(2)}
                            <span className=" text-[1rem] pl-3">APT</span>
                          </div>
                        </div>
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem] flex">
                            <span> Total Gas Used in USD</span>
                          </div>
                          <div className="  text-[#FFFFFFCC] text-[2.25rem]">
                            $
                            <span className="pl-1 text-[2.25rem] font-[800]">
                              {particularFeePayerTotalgasinUSD}
                            </span>
                            <span className=" text-[1rem] pl-3">USD</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" w-full xxl:mt-3 xl:mt-3 sxl:mt-3 bxl:mt-3 lg:mt-3 md:mt-3 sm:mt-3 xd:mt-3 flex xxl:flex-row xl:flex-row sxl:flex-row bxl:flex-row lg:flex-col md:flex-col sm:flex-col xd:flex-col ">
                    <div className=" xxl:w-1/2 xl:w-1/2 sxl:w-1/2 bxl:w-1/2 lg:w-full md:w-full sm:w-full xd:w-full xxl:bg-[#17181A] xl:bg-[#17181A] sxl:bg-[#17181A] bxl:bg-[#17181A] lg:bg-[#17181A] md:bg-transparent sm:bg-transparent xd:bg-transparent border-2 md:border-none sm:border-none xd:bordern border-[#FFFFFF1A] rounded-[1rem] h-[37.125rem] overflow-auto no-scrollbar">
                      <Tabs>
                        <TabList className="flex justify-between">
                          <div className=" flex justify-normal h-[3.5rem] border-b-2 border-[#ffffff1a] w-[100%]">
                            {/* <Tab
                    className={`cursor-pointer outline-none Tab_container p-[1.05rem]  ${
                      selectedTab === "users" ? "bg-[#252628]" : ""
                    }`}
                    onClick={() => handleTabChange("users")}
                  >
                    <div className="text-[#FFFFFF] text-[0.875rem] font-[800]">
                      Top Users
                    </div>
                  </Tab> */}
                            <Tab
                              className={`cursor-pointer outline-none Tab_container p-[1.05rem] ${
                                selectedTab === "functions"
                                  ? "bg-[#252628]"
                                  : ""
                              }`}
                              onClick={() => handleTabChange("functions")}
                            >
                              <div className="text-[#FFFFFF] text-[0.875rem] font-[800]">
                                Top Functions
                              </div>
                            </Tab>
                          </div>
                          {/* <div className=" flex text-[0.75rem] text-[#2ED3B7] font-[800] gap-[1rem]">
                  <img src={filter} alt="Filter" className=" w-5 h-5" />
                  <span className=" p-1">This week</span>
                </div> */}
                        </TabList>
                        {/* <TabPanel>
                <div className=" h-[4.625rem] bg-[#111213] border-b-2 border-[#ffffff1a] flex justify-between p-4 w-full">
                  <div className=" flex flex-col">
                    <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                      Total Users
                    </div>
                  </div>
                  <div className="text-[1rem] text-[#FFFFFFCC] font-[800]"></div>
                </div>
                <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                  <div className="bg-[#111213] rounded-[1rem] border-2 border-[#ffffff1a] w-[38.75rem] h-[5rem] flex justify-between p-3">
                    <div className=" flex flex-col">
                      <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                        0xe22c926731...b1fc0b5e6c6f32fbf4
                      </div>
                      <div className="flex">
                        <div className=" text-[#777879] text-[0.875rem] font-[400]">
                          Balance
                        </div>
                        <div className="font-[800] text-[#777879] text-[0.875rem] pl-2">
                          1,234.00 APT
                        </div>
                        <div className="text-[#12B76A] font-[400] text-[0.875rem] pl-2">
                          . Active
                        </div>
                      </div>
                    </div>
                    <div className="text-[#FFFFFFCC] font-[800]">
                      12,345 USD
                    </div>
                  </div>
                </div>
                <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                  <div className="bg-[#111213] rounded-[1rem] border-2 border-[#ffffff1a] w-[38.75rem] h-[5rem] flex justify-between p-3">
                    <div className=" flex flex-col">
                      <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                        0xe22c926731...b1fc0b5e6c6f32fbf4
                      </div>
                      <div className="flex">
                        <div className=" text-[#777879] text-[0.875rem] font-[400]">
                          Balance
                        </div>
                        <div className="font-[800] text-[#777879] text-[0.875rem] pl-2 ">
                          1,234.00 APT
                        </div>
                        <div className="text-[#12B76A] font-[400] text-[0.875rem] pl-2">
                          . Active
                        </div>
                      </div>
                    </div>
                    <div className="text-[#FFFFFFCC] font-[800]">
                      12,345 USD
                    </div>
                  </div>
                </div>
              </TabPanel> */}
                        <TabPanel>
                          <div className=" h-[4.625rem] bg-[#111213] border-b-2 border-[#ffffff1a] flex justify-between w-full p-4">
                            <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                              Total Functions Count
                            </div>
                            <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                              {overallFunctionData &&
                                overallFunctionData?.toLocaleString()}
                            </div>
                          </div>
                          {particularTopFunctions.map((item: any) => (
                            <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                              <div className="bg-[#111213] rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[90%] xl:w-[90%] sxl:w-[85%] bxl:w-[85%] lg:w-[85%] sm:w-full md:w-full xd:w-full h-[5rem] flex justify-between p-4">
                                <div className=" truncate text-[0.875rem] text-[#FFFFFF] ">
                                  <span className="">
                                    {item.entry_function_name}
                                  </span>
                                </div>
                                <div className="text-[1rem] text-[#FFFFFF] flex ">
                                  <span>
                                    {item.total_function_count &&
                                      item.total_function_count.toLocaleString()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </TabPanel>
                      </Tabs>
                    </div>
                    <div
                      className="xxl:w-1/2 xl:w-1/2 sxl:w-1/2 bxl:w-1/2 lg:w-full md:w-full sm:w-full xd:w-full bg-[#17181A] border-2 border-[#FFFFFF1A] rounded-[1rem] h-[37.125rem] ml-2 md:ml-0 sm:ml-0 xd:ml-0 overflow-auto xxl:mt-0 xl:mt-0 sxl:mt-0 
              bx:mt-0 lg:mt-4 md:mt-4 sm:mt-3 xd:mt-3"
                    >
                      <div className="h-[3.5rem] border-b-2 border-[#ffffff1a] flex justify-between p-3">
                        <div className="text-[#FFFFFF] text-[0.875rem] font-[800]">
                          Last 6 Recent Transactions
                        </div>
                      </div>
                      <div className="h-[4.625rem] bg-[#111213] border-b-2 border-[#ffffff1a] p-4">
                        <div className="flex">
                          <div className="text-[1rem] text-[#FFFFFFCC] font-[400] w-[20%]">
                            Versions
                          </div>
                          <div className="text-[1rem] text-[#FFFFFFCC] font-[400] w-[25%] flex justify-start">
                            Sender Address
                          </div>
                          <div className="text-[1rem] text-[#FFFFFFCC] font-[400] w-[25%] flex justify-start ">
                            Functions
                          </div>
                          <div className="text-[1rem] text-[#FFFFFFCC] font-[400] w-[25%] flex justify-end">
                            Gas Used
                          </div>
                        </div>
                        <div className="text-[1rem] text-[#FFFFFFCC] font-[800] "></div>
                      </div>
                      {data.length === 0 ? (
                        <div className="text-[1rem] text-[#FFFFFFCC] font-[800]  flex justify-center ">
                          No recent transactions
                        </div>
                      ) : (
                        data.map((item, index) => (
                          <div
                            key={index}
                            className="h-[4.625rem] border-b-2 border-[#ffffff1a] p-4"
                          >
                            <div className="flex">
                              <a
                                className="text-[1rem] text-[#2ED3B7] font-[800] w-[20%]"
                                href={`https://explorer.aptoslabs.com/txn/${
                                  item?.version
                                }?network=${
                                  network?.name?.toLowerCase() === "mainnet"
                                    ? "mainnet"
                                    : "testnet"
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={item?.version}
                              >
                                {item?.version}
                              </a>
                              <div className="text-[1rem] text-[#FFFFFFCC] font-[800] w-[25%] flex justify-start">
                                {item?.sender &&
                                  item.sender.slice(0, 6) +
                                    "..." +
                                    item.sender.slice(-4)}
                              </div>
                              <div className="text-[1rem] text-[#FFFFFFCC] font-[800] w-[25%] flex justify-start truncate">
                                {item?.payload?.function &&
                                  item.payload.function
                                    .split("::")
                                    .slice(-2)
                                    .join("::")}
                              </div>
                              <div className="text-[1rem] text-[#FFFFFFCC] font-[800] w-[25%] flex justify-end">
                                {item?.gas_used &&
                                  (item.gas_used / 1000000).toFixed(6)}
                                APT
                              </div>
                            </div>
                            <div className="text-[1rem] text-[#FFFFFFCC] font-[800] "></div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  {isPopupOpen && (
                    <AdminDeposit
                      handleCloseDepositModel={handleCloseDepositModel}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DappDetails;
